import React, { FunctionComponent } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import InputMask, { Props as InputMaskProps } from 'react-input-mask';

const MaskedTextField: FunctionComponent<
    TextFieldProps & Pick<InputMaskProps, 'value' | 'onChange' | 'mask'>
    > = props => {
    const { value, onChange, mask, onBlur, disabled, ...otherProps } = props;

    return <InputMask
        mask={ mask }
        value={ value }
        onChange={ onChange }
        onBlur={ onBlur }
        disabled={ disabled }
    >
        { (() => <TextField { ...otherProps } disabled={ disabled }/>) as any }
    </InputMask>;
};

export default MaskedTextField;
